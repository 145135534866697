import { Column } from "primereact/column";
import { SharedTableColumnConfig } from "../types/SharedTableColumnConfig";
import { columnWidth } from "./DefectsTableDataHelper";
import {
    columnTemplates,
    filterTemplates
} from "../components/DefectsTable/Constants";
import {
    notesEditorTemplate,
    notesPlaceholderTemplate
} from "../components/DefectsTable/BodyTemplates";
import { DisplayModalContent } from "../components/Shared/DisplayModal";
import { DefectDataItem } from "../types/DefectsDataItem";

export const renderColumnComponents = (
    columnConfig: SharedTableColumnConfig[],
    aiGradingActive: boolean,
    handleColumnUpdate: (defectId: number, updatedColumn: string, value: any) => void,
    handleReadMoreClick: (content: DisplayModalContent) => void,
    selectVessel: (rowData: any) => void,
    filteredData: DefectDataItem[],
    onCellEditComplete: (e: any) => Promise<void>,
) => {
    const result = columnConfig.map((col) => {
        const columnStyle = {
            ...columnWidth(col.field),
            WhiteSpace: "normal"
        };
        return (
            <Column
                key={col.field}
                field={col.field}
                dataType={col.dataType}
                header={col.header}
                filterMatchMode={col.filterMatchMode}
                frozen={col.frozen}
                className={col.showFilterMenu ?? false ? "has-filter-menu" : ""}
                headerClassName={`${col.field
                    .split(".")
                    .slice(-1)
                    .pop()}-header`}
                sortable={col.sortable ?? false}
                showFilterMenu={col.showFilterMenu ?? false}
                showFilterMatchModes={col.showFilterMatchModes ?? false}
                filter={col.filter ?? false}
                filterField={col.filterField}
                filterFunction={col.filterFunction}
                sortFunction={col?.sortFunction}
                body={
                    columnTemplates(
                        aiGradingActive,
                        handleColumnUpdate,
                        handleReadMoreClick,
                        selectVessel
                    ).find((e: any) => e.column === col.field)?.bodyTemplate ??
                    null
                }
                bodyStyle={{
                    textAlign: col.textAlign ?? "left",
                    justifyContent: col.justifyContent ?? "start"
                }}
                style={columnStyle}
                filterElement={
                    filterTemplates(filteredData).find(
                        (e) => e.column === col.field
                    )?.filterTemplate ?? null
                }
                filterPlaceholder={col.filterPlaceholder ?? ""}
                editor={
                    col.field === "notes"
                        ? (rowData) => notesEditorTemplate(rowData)
                        : ""
                }
                onBeforeCellEditShow={notesPlaceholderTemplate}
                onCellEditComplete={onCellEditComplete}
                exportable={col.exportable ?? false}
            />
        );
    });
    return result;
};
