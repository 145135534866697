import { useEffect, useState } from "react";
import { ClosedRatioGraph } from "./ClosedRatioGraph";
import { CumulativeDefectsGraph } from "./CumulativeDefectGraph";
import { DefectInsightFilterBy } from "../../types/DefectInsightsData";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { AgeOfOpenDefectsGraph } from "./AgeOfOpenDefectsGraph";
import { DEFECTS_INSIGHTS, DEFECTS_INSIGHTS_VIEW_VESSELS, DEFECTS_INSIGHTS_VIEW_TECHNICAL_MANAGERS } from "../../constants/analytics";
import { sendGAEvent } from "../../AnalyticsService";
import { ClearSelectedVesselButton } from "../Shared/ClearSelectedVesselButton";
import { getFilterValues } from "../../utils/DefectInsightsGraphHelper";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectFilteredDefects, selectInsightsFilterBy, selectSelectedVessel } from "../../store/selectors/defectSelectors";
import { setInsightsFilterBy, setSelectedVessel } from "../../store/slices/defectSlice";
import { clearAllFilters } from "../../store/thunks/filtersThunks";
import { selectSelectedTechnicalManagers, selectSelectedVesselAgeBands, selectSelectedVessels, selectSelectedVesselTypes } from "../../store/selectors/filtersSelectors";

export const DefectInsights = () => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const filteredDefects = useAppSelector(selectFilteredDefects);
    const selectedVessel = useAppSelector(selectSelectedVessel);
    const insightsFilterBy = useAppSelector(selectInsightsFilterBy);
    const selectedVessels = useAppSelector(selectSelectedVessels);
    const selectedVesselAgeBands = useAppSelector(selectSelectedVesselAgeBands);
    const selectedVesselTypes = useAppSelector(selectSelectedVesselTypes);
    const selectedTechnicalManagers = useAppSelector(selectSelectedTechnicalManagers);
    
    const [filterValues, setFilterValues] = useState<string[] | undefined>([]);

    useEffect(() => {
        if (
            !selectedVessel &&
            !selectedVessels.length &&
            !selectedVesselAgeBands.length &&
            !selectedVesselTypes.length &&
            !selectedTechnicalManagers.length
        ) {
            setFilterValues(undefined);
        } else {
            setFilterValues(getFilterValues(filteredDefects, insightsFilterBy, selectedVessel))
        }
    }, [insightsFilterBy, filteredDefects, selectedVessel]);

    const onChangeFilterBy = (event: DropdownChangeEvent) => {
        dispatch(setInsightsFilterBy(event.value));
        sendGAEvent(DEFECTS_INSIGHTS,event.value === DefectInsightFilterBy.VESSELS 
            ? DEFECTS_INSIGHTS_VIEW_VESSELS : DEFECTS_INSIGHTS_VIEW_TECHNICAL_MANAGERS);
    };

    const options = Object.values(DefectInsightFilterBy).map((option) => ({
        label: t(`defectInsights.${option}`),
        value: option,
    }));

    const onClickClear = () => {
        dispatch(clearAllFilters());
        dispatch(setInsightsFilterBy(DefectInsightFilterBy.VESSELS));
        dispatch(setSelectedVessel(""));
    }

    return (
        <div>
            <div className="flex flex-row justify-content-between mb-4">
                {selectedVessel ? <ClearSelectedVesselButton /> : (
                    <Button
                        id={"clearAllFilters"}
                        className={"p-button p-button-outlined p-button-secondary secondary-outlined-button"}
                        data-cy="clear-all-filters"
                        onClick={onClickClear}>
                        {t("fleetGraph.clear")}
                    </Button>
                )}
                <div className="flex flex-row align-items-center">
                    <p>
                        {t("defectInsights.show")}
                    </p>
                    <Dropdown value={insightsFilterBy} onChange={onChangeFilterBy} options={options} className="md:w-15rem ml-3" />
                </div>
            </div>
            <ClosedRatioGraph filterBy={insightsFilterBy} filterValues={filterValues} />
            <CumulativeDefectsGraph filterBy={insightsFilterBy} filterValues={filterValues} />
            <AgeOfOpenDefectsGraph filterBy={insightsFilterBy} filterValues={filterValues} />
        </div>
    );
}