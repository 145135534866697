import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetGraphDataItem } from '../../types/FleetGraphDataItem';
import { VesselSubgradeResponse } from '../../types/VesselSubgradeResponse';
import { SubgradeBackingData } from '../../types/SubgradeBackingData';

export interface FleetGraphState {
    data: FleetGraphDataItem[];
    loading: boolean;
    error: boolean;
    activeTab: number;
    subgradeData: VesselSubgradeResponse | undefined;
    subgradeLoading: boolean;
    subgradeError: boolean;
    subgradeBackingData: SubgradeBackingData | undefined;
    subgradeBackingLoading: boolean;
    subgradeBackingError: boolean;
    subgradeActiveTab: number;
}

export const initialState: FleetGraphState = {
    data: [],
    loading: true,
    error: false,
    activeTab: 0,
    subgradeData: undefined,
    subgradeLoading: true,
    subgradeError: false,
    subgradeBackingData: undefined,
    subgradeBackingLoading: true,
    subgradeBackingError: false,
    subgradeActiveTab: 0,
};

export const fleetGraphSlice = createSlice({
    name: 'fleetGraph',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<FleetGraphDataItem[]>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload;
        },
        setSubgradeData: (state, action: PayloadAction<VesselSubgradeResponse | undefined>) => {
            state.subgradeData = action.payload;
        },
        setSubgradeLoading: (state, action: PayloadAction<boolean>) => {
            state.subgradeLoading = action.payload;
        },
        setSubgradeError: (state, action: PayloadAction<boolean>) => {
            state.subgradeError = action.payload;
        },
        setSubgradeBackingData: (state, action: PayloadAction<SubgradeBackingData | undefined>) => {
            state.subgradeBackingData = action.payload;
        },
        setSubgradeBackingLoading: (state, action: PayloadAction<boolean>) => {
            state.subgradeBackingLoading = action.payload;
        },
        setSubgradeBackingError: (state, action: PayloadAction<boolean>) => {
            state.subgradeBackingError = action.payload;
        },
        setSubgradeActiveTab: (state, action: PayloadAction<number>) => {
            state.subgradeActiveTab = action.payload;
        },
    },
});

export const { 
    setData,
    setLoading,
    setError,
    setActiveTab,
    setSubgradeData,
    setSubgradeLoading,
    setSubgradeError,
    setSubgradeBackingData,
    setSubgradeBackingLoading,
    setSubgradeBackingError,
    setSubgradeActiveTab,
} = fleetGraphSlice.actions;

export default fleetGraphSlice.reducer;
