import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "primereact/column";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { columnWidth } from "../../utils/DefectsTableDataHelper";
import {
    getSharedDefectsColumnsConfig,
    registerCustomFilters
} from "./Filters/FilterTemplates";
import DisplayModal, {
    DisplayModalContent,
    DisplayModalMethods
} from "../Shared/DisplayModal";
import {
    filterSharedDefectsTemplates,
    keywordSharedDefectsSearchFilterFields,
    sharedDefectsTemplates
} from "./Constants";
import { SharedDefectsHeader } from "../Shared/SharedDefectsHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectSharedDefects } from "../../store/selectors/sharedDefectsSelectors";
import { errorToast } from "@idwal/idwal-react-components";
import { useFlags } from "launchdarkly-react-client-sdk";
import { selectDataTableFilterData } from "../../store/selectors/filtersSelectors";
import { setSharedDefectsTableFilterData } from "../../store/slices/filtersSlice";
import { setPage } from "../../store/slices/sharedDefectsSlice";
import { SharedDefectItem } from "../../types/SharedDefectItem";

export const SharedDefectsTable = () => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const {
        data: sharedDefects,
        loading,
        error,
        page
    } = useAppSelector(selectSharedDefects);
    const { sharedDefects: filters } = useAppSelector(
        selectDataTableFilterData
    );

    const { defectMapping } = useFlags();

    const displayModal = useRef<DisplayModalMethods>(null);
    const defectTable = useRef<any>(null);

    const [filteredData, setFilteredData] = useState<SharedDefectItem[]>([]);
    const [first, setFirst] = useState(page.first);
    const [rows, setRows] = useState(page.row);

    const columns = useMemo(
        () => getSharedDefectsColumnsConfig(defectMapping),
        []
    );

    useEffect(() => {
        if (sharedDefects && defectTable.current !== null) {
            setFilteredData(sharedDefects);
        }
    }, [sharedDefects]);

    registerCustomFilters();

    const handleReadMoreClick = useCallback(
        (content: DisplayModalContent) => {
            if (displayModal.current) {
                displayModal.current.showContent(content);
            }
        },
        [displayModal.current]
    );

    const onPage = useCallback((event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        dispatch(setPage({ row: event.rows, first: event.first }));
    }, []);

    const header = useMemo(
        () => (
            <SharedDefectsHeader
                columns={columns}
                disabled={loading}
                selectedColumns={columns}
                filters={filters}
                dataTableRef={defectTable}
                filteredData={filteredData}
            />
        ),
        [columns, loading, defectTable, filteredData]
    );

    const onFilter = useCallback((event: DataTableStateEvent) => {
        dispatch(setSharedDefectsTableFilterData(event.filters));
    }, []);

    const columnComponents = useMemo(() => {
        const result = columns.map((col) => {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    dataType={col.dataType}
                    header={col.header}
                    filterMatchMode={col.filterMatchMode}
                    frozen={col.frozen}
                    className={
                        col.showFilterMenu ?? false ? "has-filter-menu" : ""
                    }
                    headerClassName={`${col.field
                        .split(".")
                        .slice(-1)
                        .pop()}-header`}
                    sortable={col.sortable ?? false}
                    showFilterMenu={col.showFilterMenu ?? false}
                    showFilterMatchModes={col.showFilterMatchModes ?? false}
                    filter={col.filter ?? false}
                    filterField={col.filterField}
                    filterFunction={col.filterFunction}
                    body={
                        sharedDefectsTemplates(handleReadMoreClick).find(
                            (e: any) => e.column === col.field
                        )?.bodyTemplate ?? null
                    }
                    bodyStyle={{
                        textAlign: col.textAlign ?? "left",
                        justifyContent: col.justifyContent ?? "start"
                    }}
                    style={columnWidth(col.field)}
                    filterElement={
                        filterSharedDefectsTemplates(sharedDefects).find(
                            (e) => e.column === col.field
                        )?.filterTemplate ?? null
                    }
                    filterPlaceholder={col.filterPlaceholder ?? ""}
                />
            );
        });
        return result;
    }, [columns]);

    if (error) {
        errorToast(t("defectsTable.apiFail"));
        return <div />;
    }

    return (
        <>
            <DisplayModal ref={displayModal} />
            <div
                className="table-container shared-defects-table-container"
                data-cy="shared-defects-table-container"
            >
                <DataTable
                    ref={defectTable}
                    paginator
                    first={first}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    rows={rows}
                    onPage={onPage}
                    globalFilterFields={keywordSharedDefectsSearchFilterFields}
                    filters={filters}
                    scrollable
                    scrollHeight="70vh"
                    data-cy="defects-table"
                    filterDisplay="row"
                    header={header}
                    value={sharedDefects}
                    onValueChange={setFilteredData}
                    responsiveLayout="scroll"
                    onFilter={onFilter}
                    loading={loading}
                    emptyMessage={t("defectsTable.noDataMessage")}
                    removableSort
                    stripedRows
                >
                    {columnComponents}
                </DataTable>
            </div>
        </>
    );
};
