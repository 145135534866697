import { getFleetToken } from "../../services/FleetDataService";
import { getUserCapabilities, isIdUser } from "../../services/UserService";
import { Capability } from "../../types/Capability";
import { selectUserId } from "../selectors/userSelectors";
import {
    setHasFleetAccess,
    setUserCapabilities,
    setUserId,
    setUserToken
} from "../slices/userSlice";
import { AppThunk } from "../store";
import {
    fetchAgeOfOpenDefects,
    fetchClosedRatios,
    fetchCumulativeDefects
} from "./defectInsightsThunks";
import { fetchDefects } from "./defectThunks";
import {
    fetchFleetData,
    fetchInspectionAndGradeData,
    setupFleetData,
    setupFleetStatsData
} from "./fleetThunks";
import {
    fetchFleetGraph,
    fetchSubgradeBackingData,
    fetchSubgradeData
} from "./fleetGraphThunks";
import {
    fetchPreviousRecipients,
    fetchSharedDefects
} from "./sharedDefectsThunks";
import { AmplifyUser } from "@aws-amplify/ui";
import { fetchNotifications } from "./notificationsThunks";
import {
    fetchPSCDeficiencies,
    fetchPSCInspections,
    setupPSCVesselData
} from "./pscThunks";
import { fetchScreeningData } from "./screeningThunks";
import { fetchFleetDataV2 } from "./fleetThunksV2";

export const initFetchData =
    (user: AmplifyUser, releaseMultiLists: boolean): AppThunk =>
    async (dispatch, getState) => {
        const userId = selectUserId(getState());

        if (user && !userId) {
            dispatch(setUserId(user.username));
            
            dispatch(fetchTokenData());

            const userCapabilities = await getUserCapabilities(user.username);

            dispatch(setUserCapabilities(userCapabilities));

            dispatch(setHasFleetAccess(isIdUser(userCapabilities)));

            const capabilities = Object.fromEntries(
                userCapabilities.map((capability) => [
                    capability,
                    userCapabilities.includes(capability)
                ])
            );

            await dispatch(
                fetchDataByCapabilities(capabilities, releaseMultiLists)
            );

            dispatch(setupFleetData());

            await dispatch(fetchSubsequentDataByCapabilities(capabilities));

            dispatch(setupFleetStatsData());
        }
    };

const fetchDataByCapabilities =
    (
        capabilities: Record<string, boolean>,
        releaseMultiLists: boolean
    ): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        const fetchActions = [];

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_FLEETS]) {
            if (releaseMultiLists) {
                fetchActions.push(dispatch(fetchFleetDataV2()));
            } else {
                fetchActions.push(dispatch(fetchFleetData()));
            }
        }

        if (
            capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES] ||
            capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]
        ) {
            fetchActions.push(dispatch(fetchInspectionAndGradeData()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS]) {
            fetchActions.push(dispatch(fetchDefects()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS]) {
            fetchActions.push(dispatch(fetchSharedDefects()));
        }

        await Promise.all(fetchActions);

        return true;
    };

const fetchSubsequentDataByCapabilities =
    (capabilities: Record<string, boolean>): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        const fetchActions = [];

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_DEFECTS]) {
            fetchActions.push(dispatch(fetchPreviousRecipients()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_GRADES]) {
            fetchActions.push(dispatch(fetchFleetGraph()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_SUBGRADES]) {
            fetchActions.push(dispatch(fetchSubgradeData()));
            fetchActions.push(dispatch(fetchSubgradeBackingData()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_NOTIFICATIONS]) {
            fetchActions.push(dispatch(fetchNotifications()));
        }

        if (capabilities[Capability.CAP_FLEETAPP_VIEW_SCREENING]) {
            fetchActions.push(dispatch(fetchScreeningData()));
        }

        await Promise.all(fetchActions);

        return true;
    };

export const fetchTokenData =
    (): AppThunk => async (dispatch) => {
        try {
            const userToken = await getFleetToken();

            if (userToken) {
                dispatch(setUserToken(userToken));

                dispatch(fetchClosedRatios());
                dispatch(fetchCumulativeDefects());
                dispatch(fetchAgeOfOpenDefects());

                const pscPromoises = [
                    dispatch(fetchPSCInspections()),
                    dispatch(fetchPSCDeficiencies())
                ];

                await Promise.all(pscPromoises);

                dispatch(setupPSCVesselData());
            }
        } catch (e) {
            console.error(e);
        }
    };
