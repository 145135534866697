import { DataTable } from "primereact/datatable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultHeader } from "../Shared/DefaultHeader";
import { HeaderType } from "../../types/HeaderType";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import {
    onCustomRestoreState,
    onCustomSaveState
} from "../../utils/TableUtils";
import { CloseDefectsDialog } from "../CloseDefectsDialog/CloseDefectsDialog";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import {
    getDefectsSummaryColumns,
    getDefectsSummaryHeaders,
    getDefectsSummaryStaticColumns,
    getDefectsSummaryStaticHeaders,
    getDefectsSummarySubHeaders,
    keywordSearchFilterFields
} from "./DefectsSummaryConfig";
import {
    filterBySelected,
    sortByReordered,
    mapElementsToSelectItemOptions,
    mapSelectedColumns,
    tallyDefectSummaryData
} from "../../utils/DefectsSummaryTableDataHelper";
import "./DefectsSummary.css";
import { useLocalStorage } from "usehooks-ts";
import { ShareDefectModal } from "../DefectsTable/ShareDefectModal";
import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import { useAppSelector } from "../../hooks/storeHooks";
import {
    selectActiveTab,
    selectFilteredDefects
} from "../../store/selectors/defectSelectors";
import {
    selectDataTableFilterData,
    selectKeywordSearch
} from "../../store/selectors/filtersSelectors";
import { DefectTabs } from "../../types/DefectTabs";

type DefectsSummaryProps = {
    isLoading: boolean;
};

export const DefectsSummary = (props: DefectsSummaryProps) => {
    const { isLoading } = props;
    const { t } = useTranslation("locale");

    const filteredDefects = useAppSelector(selectFilteredDefects);
    const { defectsSummary: keywordSearch } =
        useAppSelector(selectKeywordSearch);
    const { defectsSummary: filters } = useAppSelector(
        selectDataTableFilterData
    );
    const activeTab = useAppSelector(selectActiveTab);

    const [defectSummaryData, setDefectSummaryData] = useState<
        DefectSummaryDataItem[]
    >([]);
    const [summaryLoading, setSummaryLoading] = useState(false);

    useEffect(() => {
        setSummaryLoading(true);
        setDefectSummaryData(tallyDefectSummaryData(filteredDefects));
        setSummaryLoading(false);
    }, [filteredDefects]);

    const staticHeaders = useMemo(() => getDefectsSummaryStaticHeaders(), []);
    const subHeaders = useMemo(() => getDefectsSummarySubHeaders(), []);
    const staticColumns = useMemo(() => getDefectsSummaryStaticColumns(), []);
    const columns = useMemo(() => getDefectsSummaryColumns(), []);

    const [headers, setHeaders] = useState(getDefectsSummaryHeaders());
    const [selectedHeaders, setSelectedHeaders] = useState(headers);
    const [selectedSubHeaders, setSelectedSubHeaders] = useState(subHeaders);
    const [selectedColumns, setSelectedColumns] = useState(columns);

    const [localStorageSelected, setLocalStorageSelected] = useLocalStorage<
        string[]
    >(`${LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY}-selected`, []);

    useEffect(() => {
        if (localStorageSelected.length) {
            const localHeaders = sortByReordered(
                filterBySelected(headers, localStorageSelected),
                localStorageSelected
            );
            const localSubHeaders = sortByReordered(
                filterBySelected(subHeaders, localStorageSelected),
                localStorageSelected
            );
            const localColumns = sortByReordered(
                filterBySelected(columns, localStorageSelected),
                localStorageSelected
            );

            setSelectedHeaders(localHeaders);
            setSelectedSubHeaders(localSubHeaders);
            setSelectedColumns(localColumns);
        }
    }, []);

    const onColumnToggle = useCallback(
        (event: MultiSelectChangeEvent) => {
            const selected = mapSelectedColumns(event.value);

            setLocalStorageSelected(selected);
            setSelectedHeaders(filterBySelected(headers, selected));
            setSelectedSubHeaders(filterBySelected(subHeaders, selected));
            setSelectedColumns(filterBySelected(columns, selected));
        },
        [headers, subHeaders, columns]
    );

    const onColumnReorder = useCallback(
        (items: { header: string; field: string }[]) => {
            const reordered = mapSelectedColumns(items);

            setLocalStorageSelected(reordered);
            setHeaders(sortByReordered(headers, reordered));
            setSelectedHeaders(sortByReordered(selectedHeaders, reordered));
            setSelectedSubHeaders(
                sortByReordered(selectedSubHeaders, reordered)
            );
            setSelectedColumns(sortByReordered(selectedColumns, reordered));
        },
        [headers, selectedHeaders, selectedSubHeaders, selectedColumns]
    );

    const loading = isLoading || summaryLoading;

    const renderHeader = useMemo(
        () => (
            <DefaultHeader
                onColumnToggle={onColumnToggle}
                columns={mapElementsToSelectItemOptions(headers)}
                disabled={loading}
                selectedColumns={mapElementsToSelectItemOptions(
                    selectedHeaders
                )}
                filters={filters}
                onColumnReorder={onColumnReorder}
                headerType={HeaderType.DEFECT_SUMMARY}
                keywordDataCy="defects-summary-keyword-search"
            />
        ),
        [loading, selectedHeaders, filters]
    );

    const renderHeaderColumnGroup = useMemo(
        () => (
            <ColumnGroup>
                <Row>
                    {staticHeaders}
                    {selectedHeaders}
                </Row>
                {selectedSubHeaders.length ? (
                    <Row>{selectedSubHeaders}</Row>
                ) : null}
            </ColumnGroup>
        ),
        [staticHeaders, selectedHeaders, selectedSubHeaders]
    );

    const customSaveState = useCallback(
        (state: any) => {
            if (activeTab === DefectTabs.summary) {
                onCustomSaveState(
                    state,
                    LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY
                );
            }
        },
        [activeTab]
    );

    const customeRestoreState = useCallback(
        () =>
            onCustomRestoreState(
                LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY,
                keywordSearch
            ),
        []
    );

    return (
        <>
            <DataTable
                paginator
                headerColumnGroup={renderHeaderColumnGroup}
                rowsPerPageOptions={[5, 10, 25, 50]}
                globalFilterFields={keywordSearchFilterFields}
                scrollHeight="55vh"
                rows={10}
                scrollable
                data-cy="defects-summary-table"
                header={renderHeader}
                value={defectSummaryData}
                customSaveState={customSaveState}
                customRestoreState={customeRestoreState}
                stateStorage="custom"
                filters={filters}
                loading={loading}
                emptyMessage={t("defectsSummary.noDataMessage")}
                removableSort
                editMode="cell"
                stripedRows
            >
                {staticColumns}
                {selectedColumns}
            </DataTable>
            <ShareDefectModal />
            <CloseDefectsDialog />
        </>
    );
};
