import { getFleetTokenV2 } from "../../services/FleetDataService";
import {
    setFleetDataItems,
    setError,
    setLoading,
    setData
} from "../slices/fleetSlice";
import {
    setListImos,
    setListNames,
    setSelectedList,
    setUserDetails
} from "../slices/userSlice";
import { AppThunk } from "../store";
import {
    addCustomColumnData,
    addSpecialColumnData,
    decodeFleetToken
} from "../../utils/UserHelper";
import { getUserDetailsV2 } from "../../services/UserService";
import { selectListImos } from "../selectors/userSelectors";
import { getShipDetailsV2 } from "../../services/ShipServiceV2";
import { selectFleetData } from "../selectors/fleetSelectors";
import { GroupDetails } from "../../types/GroupDetails";


export const fetchFleetDataV2 = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
        await dispatch(fetchFleetTokenV2());
        await dispatch(fetchShipDetailsV2());

        dispatch(setLoading(false));

        await dispatch(fetchUserDetailsV2());
    } catch (e) {
        console.error(e);
        dispatch(setError(true));
    }
};

export const fetchFleetTokenV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch) => {
        try {
            const response = await getFleetTokenV2();

            if (response) {
                const decoded = decodeFleetToken(response);

                // redux-persist these in future ticket
                dispatch(setListNames(decoded.lists));
                dispatch(setListImos(decoded.vessels));
                dispatch(setSelectedList(decoded.default_list));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchShipDetailsV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const imos = selectListImos(getState());

        try {
            const response = await getShipDetailsV2(imos);

            if (response) {
                const fleetDataItems = response.map((ship: any) => ({
                    vessel: {
                        imo: `${ship.imo}`,
                        vesselName: ship.vessel_name,
                        type: ship.vessel_type,
                        // subtype: ship.shiptype_level_2,
                        technicalManager: ship.technical_manager,
                        classSociety: ship.classification_society,
                        flag: ship.flag_state,
                        dwt: ship.deadweight,
                        buildYear: ship.year_of_build,
                        shipAge: Number(ship.ship_age),
                        countryOfBuild: ship.build_country,
                        teu: Number(ship.teu),
                        riskLevel: 0,
                        notes: "",
                        group: "",
                        specialSurveyDate: undefined
                    },
                    inspection: {}
                }));

                // Can remove setData once V2 flag is removed
                dispatch(setData({ fleetDataItems, userDetails: undefined }));
                dispatch(setFleetDataItems(fleetDataItems));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchUserDetailsV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        try {
            const response = await getUserDetailsV2();

            if (response) {
                dispatch(setUserDetails(response));

                const { fleetDataItems } = selectFleetData(getState());
                const { groupDetails } = response;

                const groupEntries = Object.entries(groupDetails);

                const fleetDataItemsWithColumnData = fleetDataItems.map(
                    (item) => {
                        const fleetDataItem = { ...item };
                        const vessel = { ...fleetDataItem.vessel };

                        groupEntries.forEach((group) => {
                            const [groupName, groupEntry] = group as [
                                string,
                                GroupDetails
                            ];

                            addSpecialColumnData(groupEntry, vessel, groupName);
                            addCustomColumnData(
                                groupEntry,
                                fleetDataItem,
                                vessel
                            );
                        });

                        return {
                            ...fleetDataItem,
                            vessel
                        };
                    }
                );

                dispatch(
                    setData({
                        fleetDataItems: fleetDataItemsWithColumnData,
                        userDetails: undefined
                    })
                );
                dispatch(setFleetDataItems(fleetDataItemsWithColumnData));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };
