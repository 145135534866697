import { GroupDetails } from "../types/GroupDetails";
import { ShipData } from "../types/ShipData";
import { UserDetails } from "../types/UserDetails";
import { jwtDecode } from "jwt-decode";
import { CUSTOM_COLUMN_PREFIX } from "./Constants";
import { FleetDataItem } from "../types/FleetDataItem";

const GROUP_DROP_DOWN_KEY = "idwal-group_drop-down";

export const getGroupNameFromLocalStore = () => {
    let localStorageGroup = sessionStorage.getItem(GROUP_DROP_DOWN_KEY);
    if (localStorageGroup === null) {
        return "All";
    }
    return localStorageGroup;
};

export function getNumberOfGroups(
    userDetails: UserDetails | undefined
): number {
    let numberOfGroups = 1;
    if (userDetails?.groupDetails) {
        numberOfGroups = Object.keys(userDetails.groupDetails).length;
    }
    return numberOfGroups;
}

export const decodeFleetToken = (token: string) => {
    // Add typing when token structure is decided
    const decoded = jwtDecode(token) as any;
    const groupLists = decoded.data.lists;

    // do by group dropdown when decided if wanted
    return groupLists[0];
};

export const addSpecialColumnData = (
    group: GroupDetails,
    shipData: ShipData,
    groupName: string,
) => {
    if (
        group?.hasOwnProperty("riskLevels") &&
        group["riskLevels"].hasOwnProperty(shipData.imo)
    ) {
        shipData.riskLevel = Number(group["riskLevels"][shipData.imo]);
        shipData.group = groupName;
    }

    if (
        group?.hasOwnProperty("notes") &&
        group["notes"].hasOwnProperty(shipData.imo)
    ) {
        shipData.notes = group["notes"][shipData.imo];
        shipData.group = groupName;
    }
};

export const addCustomColumnData = (
    group: GroupDetails,
    fleetDataItem: FleetDataItem,
    vessel: ShipData
) =>
    Object.keys(group).forEach((key) => {
        if (key.startsWith(CUSTOM_COLUMN_PREFIX)) {
            if (group[key]) {
                if (group[key][vessel.imo]) {
                    fleetDataItem[key] = group[key][vessel.imo];
                } else {
                    fleetDataItem[key] = "";
                }

                fleetDataItem[`${key}.dataType`] = group[key]["dataType"];
            }
        }
    });
