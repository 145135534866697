import {FleetDataResponse} from "../types/FleetDataResponse";
import {dataRequest, postRequest} from "./DataRequest";
import mockData from "./mockFleetTableData.json"
import mockUserDetails from "./mockUserDetails.json"
import {FleetDataItem} from "../types/FleetDataItem";
import {ShipData} from "../types/ShipData";
import {UserDetails} from "../types/UserDetails";
import { InspectionAndGradeDataResponse } from "../types/InspectionAndGradeDataResponse";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import axios from "axios";
import { AiGradeInfo } from "@idwal/idwal-react-components";
import { debounce } from "lodash";
import { InternalGradeData } from "../types/InternalGradeData";
import { addCustomColumnData, addSpecialColumnData } from "../utils/UserHelper";

export const getFleetData = async (): Promise<FleetDataResponse> => {
    const request: FleetDataResponse = await dataRequest("/Fleet");
    let items : FleetDataItem[] = [];

    if (request.userDetails?.fleet) {
        const { fleet, groupDetails } = request.userDetails;

        if (groupDetails) {
            const fleetParsed = JSON.parse(fleet);

            Object.values(fleetParsed).forEach(ship => {
                const shipData : ShipData = ship as ShipData;
                let group = groupDetails[shipData.group];
                shipData.riskLevel = 0;
                shipData.notes = "";
    
                if (group) {
                    addSpecialColumnData(group, shipData, shipData.group);
    
                    const item: FleetDataItem = {
                        inspection: {},
                        vessel: shipData,
                        trending: null
                    }
        
                    addCustomColumnData(group, item, shipData);
                    items.push(item);
                }
            });
        }
    }

    return Promise.resolve({
        fleetDataItems: items,
        userDetails: request.userDetails as UserDetails,
    });
}


export const getMockFleetData = async (): Promise<FleetDataResponse> => {
    return Promise.resolve({
        fleetDataItems: mockData,
        userDetails: mockUserDetails,

    });
}

const debouncedUpdateFleetAppState = debounce((appState) => postRequest("/Users/UpdateUser", appState), 1000);

export const updateFleetAppState = (appState: any) => {
    debouncedUpdateFleetAppState(appState);
}

export const getFleetToken = async () => {
    const response = await dataRequest("/Fleet/token");
    return response.token;
}

export const getFleetTokenV2 = async () => {
    const response = await dataRequest("/Fleet/v2/token");
    return response.token;
}

const setAiGradeData = (aiGradeData: AiGradeInfo) => {
    if (aiGradeData?.predictedGradeMin && aiGradeData.predictedGradeMax && aiGradeData.predictedGrade) {
        aiGradeData.predictedGrade = Math.round(aiGradeData.predictedGrade);
        aiGradeData.predictedGradeMin = Math.round(aiGradeData.predictedGradeMin);
        aiGradeData.predictedGradeMax = Math.round(aiGradeData.predictedGradeMax);
    }

    if (aiGradeData?.evolutionGradeMin && aiGradeData.evolutionGradeMax) {
        aiGradeData.evolutionGradeMin = Math.round(aiGradeData.evolutionGradeMin);
        aiGradeData.evolutionGradeMax = Math.round(aiGradeData.evolutionGradeMax);
    }
}

export const getInspectionAndGradeData = async () : Promise<InspectionAndGradeDataResponse> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + "/Fleet/Inspection";

    const token = await getCurrentSessionIdToken();

    return axios.post<any>(url, null, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        const fleetDataItems: FleetDataItem[] = [];
        let groupNames : string[] = [];

        for (const key in result.data.combinedData) {
            const resultAiGradeData = result.data.combinedData[key].vesselGradeInfo;
            const resultInspection = result.data.combinedData[key].inspection;
            const resultVessel = result.data.combinedData[key].vessel;
            const resultDate = result.data.combinedData[key].scheduledDate;
            const scoreTrends = result.data.combinedData[key].scoreTrends;
            const scoreHistory = resultInspection?.scoreHistory;

            if(!groupNames.includes(resultVessel.group)){
                groupNames.push(resultVessel.group);
            }

            setAiGradeData(resultAiGradeData);

            fleetDataItems.push({
                inspection : resultInspection,
                vessel : resultVessel,
                aiGrades: resultAiGradeData ?? {},
                scheduledDate: resultDate,
                scoreTrends: scoreTrends,
                scoreHistory: scoreHistory
            });

        }
        return {
            fleetDataItems: fleetDataItems,
            groupCount : groupNames.length
        }
    });
}

export const getInternalGradeData = async (imos: string) : Promise<InternalGradeData[]> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + `/Grades?imos=${imos}`;

    const token = await getCurrentSessionIdToken();

    return axios.get<any>(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        return result.data.data;
    });
}

export const getFleetStats = async () => {
    return await dataRequest("/Fleet/stats");
}